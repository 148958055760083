import "./App.css";
import { NormalBgcForm } from "./Components/NormalBgcForm";

function App() {
  return (
    <div className="App">
      <NormalBgcForm />
    </div>
  );
}

export default App;
